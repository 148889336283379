import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useProduct, useTrip } from "../api/useProductApi"
import { TripParticipantsTable } from "./TripParticipants"
import { BackButton } from "../../../../components/commons/button"
import { TripHeader } from "./components/TripHeader"
import { TripTabJournal } from "./components/TripTabJournal"
import { TripTabEdit } from "./components/TripTabEdit"
import { ProductTypeChip } from "../../../../components/events/ProductTypeChip"
import {
  WakacjeChip,
  WakacjeChipVariant
} from "../../../../components/WakacjeChip"
import { MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import HumanIcon from "../../../../components/icons/HumanIcon"
import JournalIcon from "../../../../components/icons/JournalIcon"
import EditIcon from "../../../../components/icons/EditIcon"

type TabData = { label: string; path: string; icon: any }

const tabs: TabData[] = [
  {
    path: "uczestnicy",
    label: "Uczestnicy",
    icon: <HumanIcon />
  },
  // { label: "Dziennik", path: "dziennik", icon: <JournalIcon /> },
  { label: "Edytuj termin", path: "details", icon: <EditIcon /> }
]

function TripPageTabs({ activeIndex, children }: any) {
  return (
    <Box>
      <Tabs
        className="border-b border-1 border-[#E6EEFD] mb-4"
        value={activeIndex}
        aria-label="trip page tab"
      >
        {tabs.map((tab: TabData, i) => (
          <Tab
            component={Link}
            to={`../${tab.path}`}
            label={
              <div className="flex justify-center items-center gap-3">
                <div
                  className={
                    activeIndex === i ? "fill-[#0D47D7]" : "fill-black"
                  }
                >
                  {tab.icon}
                </div>
                <p>{tab.label}</p>
              </div>
            }
            key={tab.path}
          />
        ))}
      </Tabs>

      {children}
    </Box>
  )
}

const TripContainer = ({ product_id, trip_id, trip }) => {
  const { data: product, loading, refetch } = useProduct(product_id)
  const navigate = useNavigate()

  const [selectedTrip, setSelectedTrip] = useState("")

  const handleTripChange = (event: any) => {
    const id = event.target.value.id
    setSelectedTrip(event.target.value as string)
    navigate(`/wydarzenia/${id}`)
  }

  useEffect(() => {
    if (trip) {
      setSelectedTrip(trip)
    }
  }, [trip])
  return (
    <div>
      <Box>
        <div className="mb-2">
          <BackButton label="Powrót" />
        </div>
        {trip && (
          <div className="flex items-start mb-2 gap-8">
            {/* left part */}
            <div className="flex-1">
              <div className="flex gap-4 items-center">
                <div>
                  <h4 className="text-2xl leading-none mb-2">
                    {trip.product?.name}
                  </h4>
                </div>

                <div className="whitespace-nowrap">
                  <ProductTypeChip type={trip.product?.type} />
                </div>
              </div>

              <div>
                <WakacjeChip
                  label={trip.status}
                  variant={WakacjeChipVariant.GREEN}
                  showStatus={true}
                />
              </div>

              <Box className=" mt-6">
                <Select
                  className="mb-2 w-[300px]"
                  size="small"
                  value={selectedTrip}
                  onChange={handleTripChange}
                >
                  {product?.trips.map((trip) => {
                    return (
                      <MenuItem key={trip.name} value={trip}>
                        {trip.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            </div>
            <div className="flex-1">{trip && <TripHeader trip={trip} />}</div>
          </div>
        )}
      </Box>

      {trip && (
        <>
          <Routes>
            <Route
              path="uczestnicy"
              element={
                <TripPageTabs activeIndex={0}>
                  <TripParticipantsTable trip={trip} />
                </TripPageTabs>
              }
            />
            {/* <Route
              path="dziennik"
              element={
                <TripPageTabs activeIndex={1}>
                  <TripTabJournal trip={trip} />
                </TripPageTabs>
              }
            /> */}
            <Route
              path="details"
              element={
                <TripPageTabs activeIndex={2}>
                  <TripTabEdit trip={trip} refetch={refetch} />
                </TripPageTabs>
              }
            />
            <Route
              path="*"
              element={
                <TripPageTabs activeIndex={2}>
                  <TripTabEdit trip={trip} refetch={refetch} />
                </TripPageTabs>
              }
            />
          </Routes>
        </>
      )}
    </div>
  )
}

export const TripPage = (props: any) => {
  const params = useParams()
  const { tripId } = params
  const { loading, data, refetch } = useTrip(tripId ?? "")

  return (
    <>
      {loading && (
        <div className="px-10">
          <Box py={4}>
            <LinearProgress />
          </Box>
        </div>
      )}
      <Box p={4}>
        {data && (
          <TripContainer
            trip={data}
            product_id={data.product_id}
            trip_id={tripId}
          />
        )}
      </Box>
    </>
  )
}
