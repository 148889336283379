import { Box, Modal, useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Link from "next/link"
import { XIcon } from "wakacje-web/src/components/Icon/XIcon"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
function formatDateString(dateString: string): string {
  const date = new Date(dateString)

  const formattedDate = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}, ${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`

  return formattedDate
}

export const InquiryMessagePopup = ({
  data,
  setOpenParticipant,
  open,
  admin = false
}) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))
  const url = admin
    ? `https://fajnyczas.pl/${data.company.fc_website_prefix}/${data.product_id}`
    : `/${data.company.fc_website_prefix}/${data.product_id}`

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: !mobile ? "40px 48px" : "20px 24px",
    borderRadius: 2,
    border: "none",
    outline: "none",
    overflow: "auto",
    height: mobile ? "auto" : "auto",
    maxWidth: "100%"
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpenParticipant(false)}
      sx={{ overflow: "auto" }}
    >
      <Box sx={modalStyle} className=" overflow-auto  md:overflow-hidden">
        <div
          className="flex items-center justify-end cursor-pointer"
          onClick={() => setOpenParticipant(false)}
        >
          <XIcon />
        </div>
        {/* <img src="/svg/ok.svg" width={38} className="mx-auto mb-4" alt="" /> */}
        <h2 className="font-bold text-3xl md:mb-8 text-center">Zapytanie</h2>
        <p className="py-5  mx-auto text-sm lg:text-base">
          <div className="flex gap-2 mt-6 md:mt-0 ">
            <span className="font-bold w-[135px] md:w-[160px]">Data:</span>
            <span className="md:w-auto whitespace-nowrap">
              {formatDateString(data.created_at)}
            </span>
          </div>
          <div className="flex gap-2 md:mt-0 ">
            <span className="font-bold w-[135px] md:w-[160px]">Firma:</span>
            <span className="md:w-auto flex-1 whitespace-nowrap">
              {data.company.name}
            </span>
          </div>
          <div className="flex gap-2">
            <span className="font-bold w-[135px] md:w-[160px]">Oferta:</span>
            <div className="  md:w-auto">
              <div>
                {data.company?.fc_website_prefix && !admin && (
                  <Link href={url}>
                    <p className="text-fc-accent-3 cursor-pointer underline hover:text-fc-accent-3 hover:no-underline">
                      {data.product.name}
                    </p>
                  </Link>
                )}
                {data.company?.fc_website_prefix && admin && (
                  <a href={url}>
                    <p className="text-fc-accent-3 cursor-pointer underline hover:text-fc-accent-3 hover:no-underline">
                      {data.product.name}
                    </p>
                  </a>
                )}
              </div>

              {/* <Box
                px={1}
                py={1}
                borderRadius={50}
                color={"var(--fc-color-accent-3)"}
                bgcolor={"#f4f4f4"}
                display="inline"
                fontSize={10}
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  whiteSpace: "nowrap"
                }}
              >
                WYCIECZKI SZKOLNE
              </Box> */}
            </div>
          </div>
          <div className="flex gap-2 mt-6 md:mt-0">
            <span className="font-bold w-[135px] md:w-[160px]">
              Przybliżony termin:
            </span>
            <span className="md:w-auto flex-1 whitespace-nowrap">
              {ProductUtils.formatDateRange(
                data.start_date?.substring(0, 10),
                data.end_date?.substring(0, 10)
              )}
            </span>
          </div>
          <div className="flex gap-2">
            <span className="font-bold w-[135px] md:w-[160px]">
              Liczba osób:
            </span>
            <span className=" w-[30%] md:w-auto flex-1">
              {data.participants_count + " osób"}
            </span>
          </div>
          <div className="flex gap-2">
            <span className="font-bold w-[135px] md:w-[160px]">
              Miejsce wyjazdu:
            </span>
            <span className=" w-[30%] md:w-auto flex-1">
              {(data.pickup_place ?? "") + " " + (data.pickup_cost ?? "")}
            </span>
          </div>
          <div className="flex flex-col md:flex-row md:gap-2 mt-6">
            <span className="font-bold w-[135px] md:w-[160px]">
              Imię i nazwisko:
            </span>
            <span className=" md:w-auto flex-1">{data.user?.name}</span>
          </div>
          <div className="flex flex-col md:flex-row md:gap-2">
            <span className="font-bold w-[135px] md:w-[160px]">
              Adres e-mail:
            </span>
            <span className=" md:w-auto ">
              {data.user_mail ?? data.user?.email}
            </span>
          </div>
          <div className="flex gap-2">
            <span className="font-bold w-[135px] md:w-[160px]">
              Numer telefonu:
            </span>
            <span className="w-[30%] md:w-auto flex-1">
              {data.user?.phone ?? data.user_phone}
            </span>
          </div>
          <div className="flex flex-col gap-3 mt-6">
            <span className="font-bold">Treść wiadomości:</span>
            <div className="w-[100%] max-w-[480px]">{data.content ?? ""}</div>
          </div>
        </p>
      </Box>
    </Modal>
  )
}
