import { Link } from "react-router-dom"
import Table from "../../../components/table/Table"
import {
  ProductStatus,
  PRODUCT_TYPE_SCHOOL_TRIP,
  ProductUtils
} from "wakacje-web/src/utils/ProductUtils"
import LockIcon from "@mui/icons-material/Lock"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import {
  ActionWithLoader,
  ProductActionWithLoader
} from "../products/ProductsTable"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import {
  WakacjeChip,
  WakacjeChipVariant
} from "../../../components/WakacjeChip"
import { useProductApi } from "./api/useProductApi"
import { useCurrency } from "../../../hooks/useCurrency"
import { useProductUtils } from "../../../hooks/useProductUtils"

export const TripsTable = ({
  listApi,
  data,
  showExpandColumn,
  showProduct,
  product,
  refetch
}: {
  listApi?: Function
  data?: any
  showExpandColumn?: boolean
  showProduct: boolean
  product?: any
  refetch?: Function
}) => {
  const { selectedCurrency } = useCurrency()
  const { formatPriceInCents } = useProductUtils()

  var columns: any = []
  if (showProduct) {
    columns = [
      ...columns,
      {
        header: "Wydarzenie",
        cell: (row: any) => <>{row.product?.name}</>,
        sort: true
      }
    ]
  }
  const { runTripCommand } = useProductApi()
  columns = [
    ...columns,
    ...[
      {
        header: "Turnus",
        cell: (row: any) => (
          <div>
            <div>{row.name}</div>
            <div>
              <WakacjeChip
                label={row.status}
                variant={WakacjeChipVariant.GREEN}
                showStatus={true}
              />
            </div>
          </div>
        ),
        sort: false
      },
      // {
      //   header: "Status",
      //   cell: (row: any) => <></>,
      //   sort: false,
      // },
      // {
      //   header: "Zapytanie",
      //   cell: (row: any) => <>{row.stat_bookings_count}</>,
      //   sort: false,
      // },
      {
        header: "Termin",
        cell: (row: any) => <>{ProductUtils.getTripDatesRange(row)}</>,
        sort: true
      }
    ]
  ]
  if (product.type === PRODUCT_TYPE_SCHOOL_TRIP) {
    columns.push({
      header: "Ilość miejsc",
      field: "available_spots"
    })
  }
  columns = [
    ...columns,
    ...[
      {
        header: "Uczestnicy",
        cell: (row: any) => {
          return (
            <>
              <p>{row.stat_participants_count}</p>
            </>
          )
        }
      },
      {
        header: "Opłaty"
      },
      {
        header: "Suma opłat",
        cell: (row: any) => {
          return (
            <>
              <p>
                {`${formatPriceInCents(
                  row.stat_payments_total,
                  row?.currency
                )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`}
              </p>
            </>
          )
        }
      }
    ]
  ]

  var sorted = [...data]
  if (sorted) {
    sorted = sorted.sort((a, b) =>
      a.start_date && b.start_date
        ? a.start_date.localeCompare(b.start_date)
        : 0
    )
  }

  const handleActivateTrip = async (trip, activate) => {
    const command = {
      type: activate ? "Trip.Activate" : "Trip.Suspend",
      aggregateId: trip.id,
      payload: {}
    }
    try {
      var res = await runTripCommand(command)
      refetch()
    } catch (err) {
      console.error("Error activating product")
    }
  }
  const handleArchiveTrip = async (trip) => {
    const command = {
      type: "Trip.Archive",
      aggregateId: trip.id,
      payload: {}
    }
    try {
      var res = await runTripCommand(command)
      refetch()
    } catch (err) {
      console.error("Error archiving product")
    }
  }

  return (
    <Table
      columns={columns}
      // listApi={listApi}
      rows={sorted}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/wydarzenia/${row.id}`}
      rowPopupBuilder={(row) => {
        return (
          <>
            <Link
              to={`/wydarzenia/${row.id}/uczestnicy`}
              className="menu-items-table-container"
            >
              <BorderColorIcon fontSize="inherit" />
              <p className="ml-4 font-normal">Edytuj turnus</p>
            </Link>
            {row.status !== ProductStatus.ACTIVE && (
              <ProductActionWithLoader
                label="Wznów zapisy"
                onClick={() => handleActivateTrip(row, true)}
                icon={LockIcon}
              />
            )}
            {row.status === ProductStatus.ACTIVE && (
              <div className="menu-items-table-container">
                <ActionWithLoader
                  onClick={() => handleActivateTrip(row, false)}
                >
                  {({ onClick, loading }) => {
                    return (
                      <div className="flex flex-row">
                        <div className="text-lg">
                          {loading ? (
                            <div className="text-base">
                              <LoadingSpinner />
                            </div>
                          ) : (
                            <LockIcon fontSize="inherit" />
                          )}
                        </div>
                        <p className="ml-4 font-normal">Wstrzymaj zapisy</p>
                      </div>
                    )
                  }}
                </ActionWithLoader>
              </div>
            )}
            {row.status !== ProductStatus.ARCHIVED && (
              <div className="menu-items-table-container">
                <ActionWithLoader onClick={() => handleArchiveTrip(row)}>
                  {({ onClick, loading }) => {
                    return (
                      <div className="flex flex-row">
                        <div className="text-lg">
                          {loading ? (
                            <div className="text-base">
                              <LoadingSpinner />
                            </div>
                          ) : (
                            <DeleteOutlineIcon fontSize="inherit" />
                          )}
                        </div>
                        <p className="ml-4 font-normal">Usuń turnus</p>
                      </div>
                    )
                  }}
                </ActionWithLoader>
              </div>
            )}
          </>
        )
      }}
    />
  )
}
