import { WakacjeChip, WakacjeChipVariant } from "../components/WakacjeChip"
import { BookingStatus } from "../../shared/wakacje-types"
import { useTranslation } from "next-i18next"

const bookingStatusTranslations = {
  all: {
    variants: ["wszystkie", "all"]
  },
  reservation_paid: {
    variants: ["rezerwacja opłacona", "reservation paid", "reservation_paid"]
  },
  new_booking: {
    variants: ["nowe zgłoszenie", "new booking", "new_booking"]
  },
  advance_paid: {
    variants: ["zaliczka opłacona", "advance paid", "advance_paid"]
  },
  cancellation: {
    variants: ["rezygnacja", "cancellation"]
  },
  reserved: {
    variants: ["zarezerwowano", "reserved"]
  }
}

const normalizeStr = (str) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()

const translateStatusLabel = (label, t) => {
  const normalizedLabel = normalizeStr(label)
  for (const key in bookingStatusTranslations) {
    const { variants } = bookingStatusTranslations[key]
    for (const variant of variants) {
      if (normalizeStr(variant) === normalizedLabel) {
        return t(`bookingStatusChip.${key}`)
      }
    }
  }
  return label
}

const getBookingStatusChipVariant = (booking) => {
  if (
    booking.status === BookingStatus.NEW ||
    booking.status === BookingStatus.RESERVED
  ) {
    return WakacjeChipVariant.YELLOW
  }
  if (
    booking.status === BookingStatus.ADVANCE_PAID ||
    booking.status === BookingStatus.FULLY_PAID
  ) {
    return WakacjeChipVariant.GREEN
  }
  if (
    booking.status === BookingStatus.CANCELED ||
    booking.status === BookingStatus.RESIGNED ||
    booking.status === BookingStatus.REJECTED
  ) {
    return WakacjeChipVariant.RED
  }

  if (booking.status === BookingStatus.REQUEST) {
    return WakacjeChipVariant.BLUE
  }
  return WakacjeChipVariant.GREY
}
export const BookingStatusChip = ({ booking }) => {
  const { t } = useTranslation()

  const variant = getBookingStatusChipVariant(booking)

  const label = translateStatusLabel(booking.status_label ?? booking.status, t)

  return <WakacjeChip label={label} variant={variant} />
}
